import React from "react";
import Letter from "./Letter";

function Board() {
  return (
    <div className="board   inter bg-[#F3F3F3] p-4 rounded-xl">
      <div className="grid grid-cols-5 gap-3">
        <Letter letterPosition={0} attemptedValue={0} />

        <Letter letterPosition={1} attemptedValue={0} />

        <Letter letterPosition={2} attemptedValue={0} />

        <Letter letterPosition={3} attemptedValue={0} />

        <Letter letterPosition={4} attemptedValue={0} />

        <Letter letterPosition={0} attemptedValue={1} />

        <Letter letterPosition={1} attemptedValue={1} />

        <Letter letterPosition={2} attemptedValue={1} />

        <Letter letterPosition={3} attemptedValue={1} />

        <Letter letterPosition={4} attemptedValue={1} />

        <Letter letterPosition={0} attemptedValue={2} />

        <Letter letterPosition={1} attemptedValue={2} />

        <Letter letterPosition={2} attemptedValue={2} />

        <Letter letterPosition={3} attemptedValue={2} />

        <Letter letterPosition={4} attemptedValue={2} />

        <Letter letterPosition={0} attemptedValue={3} />

        <Letter letterPosition={1} attemptedValue={3} />

        <Letter letterPosition={2} attemptedValue={3} />

        <Letter letterPosition={3} attemptedValue={3} />

        <Letter letterPosition={4} attemptedValue={3} />

        <Letter letterPosition={0} attemptedValue={4} />

        <Letter letterPosition={1} attemptedValue={4} />

        <Letter letterPosition={2} attemptedValue={4} />

        <Letter letterPosition={3} attemptedValue={4} />

        <Letter letterPosition={4} attemptedValue={4} />

        <Letter letterPosition={0} attemptedValue={5} />

        <Letter letterPosition={1} attemptedValue={5} />

        <Letter letterPosition={2} attemptedValue={5} />

        <Letter letterPosition={3} attemptedValue={5} />

        <Letter letterPosition={4} attemptedValue={5} />
      </div>
    </div>
  );
}

export default Board;
