import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal } from "reactstrap";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ReactModel({ modal, setModal }) {
  // Modal open state
  // Effect to show the modal after five seconds
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      setModal(true);
    }, 1000);

    // Clear the timer if the component unmounts before it triggers
    return () => clearTimeout(timer);
  }, []);
  // Toggle for Modal
  const toggle = () => setModal(!modal);

  return (
    <>
      <Modal
        isOpen={modal}
        // size="lg"
        toggle={toggle}
        modalTransition={{ timeout: 1000 }}
        className="modal-dialog-centered"
      >
        <div class="rounded1  pb-3 bg-[#EAF8FD]">
          <div class="d-flex p-3  align-items-center">
            <h1
              class="text-center font-25 inter mx-auto text-customBlue fw-bold"
              id="exampleModalLabel"
            >
              LinkQuest
            </h1>
            {/* <button
              type="button"
              class=" justify-content-end bg-transparent text-white"
              onClick={toggle}
            >
              X
            </button> */}
          </div>

          <div className="d-flex justify-content-start flex-column ps-3 pe-3">
            <h4 className=" text-2xl inter fw-bold">How to Play</h4>
            <h6 className="m-0 text-sm  inter">Play while you wait.</h6>
            <p className="p-0 text-xs  inter">You only have 6 tries</p>
          </div>
          <div className="ps-2 pe-3 spanTgas inter ">
            <div class=" ">
              <span className="bg-success !text-white">H</span>
              <span>O</span>
              <span>U</span>
              <span>R</span>
              <span>S</span>
              <p className="mt-1 ms-1 text-xs ">
                H is in the Correct Word In The Correct Spot
              </p>
            </div>
            <div class=" mt-3">
              <span>U</span>
              <span>N</span>
              <span className="!bg-amber-700	 !text-white">I</span>
              <span>T</span>
              <span>E</span>
              <p className="mt-1  ms-1 text-xs">
                I is in the word and but is in the incorrect spot.
              </p>
            </div>
            <div class="">
              <span>T</span>
              <span>A</span>
              <span>L</span>
              <span>L</span>
              <span className="!bg-red-900	 !text-white">Y</span>
              <p className="mt-1  ms-1 text-xs">
                Y is not in the word in any spot.
              </p>
            </div>
          </div>
          <div className="mx-auto text-center inter font-semibold">
            <button
              type="button"
              className="bg-[#EAF8FD]  text-customBlue p-2 h-12 rounded-xl  min-w-28 text-sm "
              onClick={() => navigate(-1)}
            >
              Cencel
            </button>
            <button
              type="button"
              className="bg-customBlue p-2 h-12 rounded-xl text-white min-w-28 text-sm "
              onClick={toggle}
            >
              OKAY
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ReactModel;
