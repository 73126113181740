import React, { useState, createContext, useEffect } from "react";
import backgroundimg from "../assets/images/headerscren.png";
import logo from "../assets/images/TimeLinkLogoBlackText.png";
import { IoChevronBackOutline } from "react-icons/io5";
import ReactModel from "../components/ReactModel";
import "../assets/css/game.scss";
import Board from "../components/wordleGame/Board";
import Keyboard from "../components/wordleGame/Keyboard";
import GameOver from "../components/wordleGame/GameOver";
import { boardDefault } from "../Words";
import { useNavigate } from "react-router-dom";
import forge from "node-forge";
import { useSelector } from "react-redux";
import Steppers from "../components/Steppers";

export const AppContext = createContext();

const QuestGame = () => {
  var password = "timelink";
  const navigate = useNavigate();
  const handelback = () => {
    navigate(-1);
  };
  const { orderDetail } = useSelector((state) => state.user);
  const encryptString = (text, password) => {
    const key = forge.pkcs5.pbkdf2(password, "salt", 10000, 32);
    const iv = forge.random.getBytesSync(16);
    const cipher = forge.cipher.createCipher("AES-CBC", key);
    cipher.start({ iv: iv });
    cipher.update(forge.util.createBuffer(text));
    cipher.finish();
    const encryptedResult = iv + cipher.output.getBytes();
    return encryptedResult;
  };

  const decryptData = (encryptedText, password) => {
    const key = forge.pkcs5.pbkdf2(password, "salt", 10000, 32);
    const iv = encryptedText.substring(0, 16);
    const ciphertext = encryptedText.substring(16);
    const decipher = forge.cipher.createDecipher("AES-CBC", key);
    decipher.start({ iv: iv });
    decipher.update(forge.util.createBuffer(ciphertext));
    decipher.finish();
    const decryptedResult = forge.util.decodeUtf8(decipher.output.getBytes());
    return decryptedResult;
  };

  useEffect(() => {
    var es = encryptString("TL-123", password);
    console.log("encrypted String", encodeURIComponent(es));
    console.log("decrypted String", decryptData(es, password));
  }, []);
  const [modal, setModal] = React.useState(false);

  const [board, setBoard] = useState(boardDefault);
  const [currentAttempt, setCurrentAttempt] = useState({
    attempt: 0,
    letter: 0,
  });
  const [disabledLetters, setDisabledLetters] = useState([]);
  const [gameOver, setGameOver] = useState({
    gameOver: false,
    guessedWord: false,
  });

  const randomWords = [
    "RIGHT",
    "BLACK",
    "HAPPY",
    "DREAM",
    "TABLE",
    "APPLE",
    "BRUSH",
    "CHAIR",
    "MELON",
    "DANCE",
    "TIGER",
    "ZEBRA",
    "SMMILE",
    "BEACH",
    "CLOUD",
    "KITES",
  ];
  const [rightWord, setRightWord] = useState(getRandomWord());
  function getRandomWord() {
    return randomWords[Math.floor(Math.random() * randomWords.length)];
  }

  useEffect(() => {
    if (currentAttempt.attempt === 0 || currentAttempt.attempt > 6) {
      setRightWord(getRandomWord());
    }
  }, [currentAttempt.attempt]);

  console.log(rightWord, "======================rendom");
  const onEnter = () => {
    if (currentAttempt.letter !== 5) return;
    let currentWord = "";
    for (let i = 0; i < 5; i++) currentWord += board[currentAttempt.attempt][i];

    setCurrentAttempt({ attempt: currentAttempt.attempt + 1, letter: 0 });

    if (currentWord === rightWord) {
      setGameOver({ gameOver: true, guessedWord: true });
      return;
    }
    // console.log(currentAttempt);
    if (currentAttempt.attempt === 5) {
      setGameOver({ gameOver: true, guessedWord: false });
      return;
    }
  };

  const onDelete = () => {
    if (currentAttempt.letter === 0) return;
    const updatedBoard = [...board];
    updatedBoard[currentAttempt.attempt][currentAttempt.letter - 1] = "";
    setBoard(updatedBoard);
    setCurrentAttempt({ ...currentAttempt, letter: currentAttempt.letter - 1 });
  };

  const onSelectLetter = (key) => {
    if (currentAttempt.letter > 4) return;
    const updatedBoard = [...board];
    updatedBoard[currentAttempt.attempt][currentAttempt.letter] = key;
    setBoard(updatedBoard);
    setCurrentAttempt({
      attempt: currentAttempt.attempt,
      letter: currentAttempt.letter + 1,
    });
  };

  return (
    <>
      <div className="!w-11/12 mx-auto p-0 game pb-3">
        <div className="w-full d-flex justify-content-between align-items-center  my-3">
          <button
            className="w-7 h-7 bg-customBlue flex justify-center items-center rounded-md text-white"
            onClick={handelback}
          >
            {<IoChevronBackOutline />}
          </button>

          <img
            src={logo}
            width={160}
            alt="w8"
            onClick={() => navigate("/order")}
          />

          <div
            className=" inter font-12 text-white bg-customBlue px-3 h-7 flex justify-center items-center rounded-full  mt-2"
            onClick={() => setModal(true)}
          >
            HOW TO
          </div>
        </div>
        <div className="mt-3 px-3 pt-4 pb-3 w-full bg-[#D7E1FE] rounded-lg shadow-md">
          <Steppers
            status={orderDetail?.status || "Notified"}
            bg={"bg-[#D7E1FE]"}
          />
        </div>
        {/* <Steppers status={orderDetail?.status} notified={orderDetail?.is_notified} /> */}
        <div className="w-full px-0">
          <div className="  mt-4 pb-4 mb-4 p-0 rubik ">
            <div>
              <div className="w-full">
                <AppContext.Provider
                  value={{
                    board,
                    setBoard,
                    currentAttempt,
                    setCurrentAttempt,
                    rightWord,
                    onEnter,
                    onDelete,
                    onSelectLetter,
                    disabledLetters,
                    setDisabledLetters,
                    gameOver,
                  }}
                >
                  <div className="game ">
                    <Board />
                    {gameOver.gameOver ? <GameOver /> : <Keyboard />}
                  </div>
                </AppContext.Provider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactModel setModal={setModal} modal={modal} />
    </>
  );
};

export default QuestGame;
