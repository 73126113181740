import React, { useState } from "react";
import "../../assets/css/style.css";
import Numberbody1 from "../../components/Numberbody1";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate , useParams} from "react-router-dom";
import { getBusinessOrderData } from "../../redux/slice/UserSlice";
import {decode} from 'base-64'

const Launchscreen = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const {BK:businessKey , oId:orderId} = useParams();
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchData = async () => {
      const dBKBytes = decode(decodeURIComponent(businessKey));
      const dOrderIdBytes = decode(decodeURIComponent(orderId));

      // Convert the decrypted bytes to strings
      const dBK = dBKBytes.toString();
      const dOrderId = dOrderIdBytes.toString();
      // const orderId = window.location.pathname.split("/")[2];
      // const businessKey = window.location.pathname.split("/")[1];
      dOrderId && localStorage.setItem("orderId", dOrderId);
      dOrderId && localStorage.setItem("continueRecursive", '123');
      dBK && localStorage.setItem("businessKey", dBK);
      console.log(dOrderId, dBK, "===========jih");
      if (!orderId) {
        navigate("/order/find");
      }else{
        if (!token) {
          navigate("/order/link");
        }else{
          dispatch(getBusinessOrderData(false , handleResponse))
        }
      }
    };

    fetchData(); // Call the async function
  }, []); // Empty dependency array to run this effect only once

  function handleResponse(status){
    if(status){
      navigate('/order')
    }else{
      alert('Whoops! We could not find this order !')
      navigate('/account')

    }
  }
  return (
    <>
    <Numberbody1/>
    </>
  );
};

export default Launchscreen;
