import { Button } from '@mui/material'
import React from 'react'
import logo from '../../assets/images/TimeLinkLogoBlackText.png'
import { useLocation } from 'react-router-dom';

const Visiting = () => {
    const location = useLocation();
    const reactionEmojies = ['😠', '😟', '😐', '🙂', '😀'];
    const queryParams = new URLSearchParams(location.search);

    // Example: Getting specific query parameters
    const bk = queryParams.get('bk'); // Gets 'param1' from URL
    const oid = queryParams.get('oid'); // Gets 'param2' from URL
console.log(bk , ' ',oid)
    return (
        <>
            <div className="d-flex flex-column bg-white justify-content-between  " style={{ minHeight: "100vh" }}>
                <div className="container text-center mt-2">

                    <img src={logo} width={150} alt="" />
                </div>
                <div className="container flex-grow-1 d-flex align-items-center  ">
                    <div className='w-100'>
                        <div className=" ">
                            <h6
                                className="inter text-start fw-bold font-24"
                            >
                                Hey there! Thanks for visiting “Business_name”
                            </h6>
                            <p
                                className="font-14  inter"
                                style={{ color: "#898989" }}
                            >Leave the store some feedback to let them know how they’re doing?</p>

                        </div>
                        <div className=" pb-0 pt-0 inter">

                            <div className="hyModal-textare">
                                <div className="d-flex gap-3 ">
                                    {
                                        reactionEmojies.map((value, index) => {
                                            return (
                                                <span>{value}</span>
                                            )
                                        })
                                    }
                                </div>
                                <textarea
                                    rows="7"
                                    className="form-control mt-4 font-14 "
                                    placeholder="Tell us what you think..."
                                //   value={feedbackMessage}
                                //   onChange={(e) => { setFeedbackMessage(e.target.value) }}
                                ></textarea>
                                <p
                                    className="font-12 mt-3 inter text-start"
                                    style={{ color: "#D1D5DB" }}
                                >
                                    Your input, our improvement, your privacy.
                                </p>
                            </div>

                            <div className="mt-3 ">


                                <Button
                                    type="button"
                                    className="btn-fill-blue p-3 py-2 rounded-3 w-100 mb-3 text-white fw-normal font-14-69 inter"
                                >
                                    Submit
                                </Button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Visiting
