/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import { IoClose } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import '../assets/css/versionThree.scss';
import useAOS from '../customHook/useAOS';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { linkUserWithOrder, seTgetErrorNull } from '../redux/slice/UserSlice';
import { Spinner } from 'reactstrap';

const PhoneNumber = () => {
    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        if (!hasAnimated) {
            setHasAnimated(true);
        }
    }, [hasAnimated]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const email = location?.state?.email || null;
    console.log(location.state)
    const { isLoading, getError } = useSelector((state) => state.user);
    const token = localStorage.getItem('accessToken');

    useEffect(() => {
        if (token) {
            navigate('/account');
        }
    }, [navigate, token]);

    const [number, setNumber] = useState('');
    const [phoneLength, setPhoneLength] = useState(false);
    const [loading, setLoading] = useState(false);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

    console.log(number?.length);

    useEffect(() => {
        if (number?.length >= 4) {
            setPhoneLength(true);
        } else {
            setPhoneLength(false);
        }
    }, [number]);

    // useEffect(() => {
    //     if (getError && getError.length > 1) {
    //         toast.error(getError, {
    //             position: "top-right",
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "light",
    //         });
    //         dispatch(seTgetErrorNull());
    //     }
    // }, [getError, dispatch]);

    const handleSubmit = () => {
        console.log("onclick")
        let businessKey = localStorage.getItem('businessKey');
        let orderId = localStorage.getItem('orderId');
        localStorage.setItem('phoneNumberForOtp', number);
        if (number === "") return;

        let data = {
            phone: number,
            businessKey: businessKey,
            orderId: orderId
        };
        if (email) { data.email = email; }
        setLoading(true);
        dispatch(linkUserWithOrder(data, handleResponse));
    };

    const handleResponse = (response) => {
        if (response?.status) {
            if (!response?.isOrderFound) {
                localStorage.setItem('isOrderFound', false);
            }
            console.log(response);
            setNumber("");
            navigate("/otp", { state: number });
        } else {
            setLoading(false);
        }
    };

    return (
        <section  className={`d-flex ${isKeyboardOpen ? 'gap-5':'justify-content-between'} manrope flex-column transition-all duration-500 ${hasAnimated ? 'translate-x-0 opacity-100' : 'translate-x-40 opacity-50'}`}
            style={{ height: screenHeight }}>
            <div>
                <div className=' p-3 '>
                    <p className='font-15 fw-semibold mb-0 flex items-center '>
                        <IoClose size={18} onClick={() => navigate(-1)} />&nbsp;Order history
                    </p>
                </div>
                <div className='new-phone-number-field p-3'>
                    <PhoneInput
                        className="fw-medium Roobert-medium"
                        international
                        defaultCountry="AU"
                        value={number}
                        placeholder="Phone Number"
                        onChange={setNumber}
                        onFocus={()=>{setIsKeyboardOpen(true)}}
                        onBlur={()=>{setTimeout(()=>{setIsKeyboardOpen(false)},1000)}}
                    />
                </div>
            </div>
            <div className={` d-flex align-items-end p-3 w-100   ${isKeyboardOpen ? 'fixed-bottom-keyboard' : 'flex-grow-1'}`}>
                <button type='button' disabled={!phoneLength}
                    onClick={handleSubmit}
                    className={`w-100 ${!phoneLength ? "btn-gray bg-gray-100 " : "btn-fill-blue "} p-3 rounded-3 font-15 fw-semibold`}>
                    {isLoading ? <Spinner size={'sm'} color='light' className='mx-auto' /> : 'Continue'}
                </button>
            </div>
            <ToastContainer />
        </section>
    );
}

export default PhoneNumber;
