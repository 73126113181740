import React, { useEffect, useState } from "react";
import logo from "../assets/images/finalLogo.png";
import "../assets/css/versionThree.scss";
import { CiCamera } from "react-icons/ci";
import { Link } from "react-router-dom";
import Unsupported from "./Unsupported";
import useAOS from "../customHook/useAOS";

const LetsOrder = () => {
  useAOS();

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize() {
      setScreenHeight(window.innerHeight);
    }
    window?.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <Unsupported class=" d-lg-block  d-none" />
      <div
        data-aos="fade-left"
        data-aos-duration="500"
        className="bg_blue p-3 manrope d-flex  flex-column  justify-content-between d-lg-none  d-block"
        style={{ height: screenHeight }}
      >
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between align-items-center ">
            <img src={logo} width={120} alt="w8" />
            <Link to="/account">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_5261_16697)">
                  <path
                    d="M17.25 9.75C17.25 12.0375 15.975 14.0625 14.625 15.3V16.5C14.625 17.25 15 18.4125 18.75 19.125C19.8088 19.3276 20.7904 19.8207 21.5851 20.5492C22.3798 21.2776 22.9562 22.2128 23.25 23.25H0.75C0.922895 22.2187 1.41321 21.267 2.15263 20.5276C2.89204 19.7882 3.84371 19.2979 4.875 19.125C8.625 18.45 9.375 17.25 9.375 16.5V15.3C8.025 14.0625 6.75 12.075 6.75 9.75V6.15C6.75 2.25 9.525 0.75 12 0.75C14.475 0.75 17.25 2.25 17.25 6.15V9.75Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5261_16697">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Link>
          </div>
          <div style={{ height: "64px" }}></div>
          <div className="text-white ">
            <h2 className="fw-bold " style={{ fontSize: "40px" }}>
              Let’s keep track.
            </h2>
            <p className=" fw-medium ">
              Link your order by tapping the NFC logo with your phone, or
              scanning the QR code.
            </p>
          </div>
        </div>
        <Link to="/qrScanner">
          <button
            type="button"
            className="w-100 bg-white p-3 rounded-3 font-15 fw-semibold flex items-center justify-center"
          >
            <CiCamera size={25} />
            &nbsp;Scan QR code
          </button>
        </Link>
      </div>
    </>
  );
};

export default LetsOrder;
